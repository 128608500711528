import React from 'react';
import { MantineProvider } from '@mantine/core';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import SocketContextProvider from './context/SocketContext';
import Dashboard from './modules/Dashboard';

TimeAgo.addDefaultLocale(en);

function App() {
  return (
    <MantineProvider
      theme={{ colorScheme: 'light' }}
      withGlobalStyles
      withNormalizeCSS
      emotionOptions={{ key: 'mantine', prepend: false }}
    >
      <SocketContextProvider>
        <Dashboard />
      </SocketContextProvider>
    </MantineProvider>
  );
}

export default App;
