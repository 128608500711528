import React, { Dispatch, SetStateAction, ReactElement } from 'react';

import {
  createStyles,
  Badge,
  Navbar,
  NavbarProps,
  ScrollArea,
  TextInput,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
  },

  aside: {
    flex: '0 0 60px',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    paddingTop: 10,
  },

  main: {
    flex: 1,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  title: {
    boxSizing: 'border-box',
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: theme.spacing.xl,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    padding: theme.spacing.md,
    paddingTop: 18,
    height: 60,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.sm,
  },

  thing: {
    boxSizing: 'border-box',
    display: 'block',
    textDecoration: 'none',
    borderRadius: theme.radius.sm,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    padding: `0 ${theme.spacing.md}px`,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.md,
    fontWeight: 500,
    height: 44,
    lineHeight: '44px',
    width: '100%',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  thingActive: {
    '&, &:hover': {
      borderLeftColor:
        theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5],
      backgroundColor:
        theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5],
      color: theme.white,
    },
  },

  thingUpdated: {
    marginLeft: 2,
  },
}));

type Thing = {
  id: string;
  updated: boolean;
};

type DashboardProps = Omit<NavbarProps, 'children'> & {
  items: Thing[];
  activeThing: string;
  setActiveThing: Dispatch<SetStateAction<string>>;
  setThingSeen: (id: string) => void; // eslint-disable-line no-unused-vars
  setSearch: (search: string) => void; // eslint-disable-line no-unused-vars
};

export function Navigation({
  items,
  activeThing,
  setActiveThing,
  setThingSeen,
  setSearch,
  ...rest
}: DashboardProps): ReactElement {
  const { classes, cx } = useStyles();

  const things = items.map((thing) => {
    const hasUpdates = activeThing !== thing.id && thing.updated;

    return (
      <a
        className={cx(classes.thing, {
          [classes.thingActive]: activeThing === thing.id,
        })}
        href="/"
        onClick={(event) => {
          event.preventDefault();
          setActiveThing(thing.id);

          if (thing.id !== 'View All') {
            setThingSeen(thing.id);
          }
        }}
        key={thing.id}
      >
        {thing.id}
        {hasUpdates && (
          <Badge className={classes.thingUpdated} color="blue">
            new
          </Badge>
        )}
      </a>
    );
  });

  const doSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <Navbar {...rest}>
      <Navbar.Section grow mx="-xs" px="xs">
        <TextInput placeholder="Search" onChange={doSearch} />
      </Navbar.Section>
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        {things}
      </Navbar.Section>
    </Navbar>
  );
}

export default Navigation;
