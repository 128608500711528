import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from 'react';

import { WS_ENDPOINT } from '../../config';
import { Message, Payload } from '../../types';

type SocketSubscriptionUpdate = (data: Payload) => void; // eslint-disable-line no-unused-vars

interface SocketContextProps {
  state: 'idle' | 'connecting' | 'connected' | 'error';
  subscribe: (
    thingID: string, // eslint-disable-line no-unused-vars
    onUpdate: SocketSubscriptionUpdate // eslint-disable-line no-unused-vars
  ) => () => void;
}

type Subscriptions = Record<string, Record<string, SocketSubscriptionUpdate>>;

interface SubscribeThing {
  type: 'SUBSCRIBE_MESSAGES';
}

interface UnsubscribeThing {
  type: 'UNSUBSCRIBE_MESSAGES';
  payload: {
    thingID: string;
  };
}

type SocketMessage = SubscribeThing | UnsubscribeThing;

export const SocketContext = createContext<SocketContextProps>({
  state: 'idle',
  subscribe: () => () => undefined,
});

interface SocketContextProviderProps {
  children?: ReactNode;
}

// eslint-disable-next-line react/function-component-definition
const SocketContextProvider: FunctionComponent<SocketContextProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<SocketContextProps['state']>('idle');
  const socket = useRef<WebSocket>();
  const subscriptions = useRef<Subscriptions>({});

  useEffect(() => {
    if (state === 'idle' && WS_ENDPOINT) {
      setState('connecting');

      socket.current = new WebSocket(WS_ENDPOINT);

      socket.current.onmessage = (event) => {
        const { data } = event;

        try {
          const message: Message = JSON.parse(data);
          const { type, payload } = message;

          if (type === 'MESSAGES_UPDATE' && payload) {
            Object.values(subscriptions.current.messages).forEach((update) => {
              update(payload as Payload);
            });
          }
        } catch (e) {
          // silent failing
        }
      };

      socket.current.onopen = () => {
        setState('connected');
      };

      socket.current.onclose = () => {
        setState('idle');
      };

      socket.current.onerror = () => {
        setState('error');
      };
    }
  }, [state, WS_ENDPOINT]);

  const sendMessage = useCallback((message: SocketMessage) => {
    if (socket.current) {
      try {
        socket.current.send(JSON.stringify(message));
      } catch (e) {
        // silent fail
      }
    }
  }, []);

  const subscribe: SocketContextProps['subscribe'] = useCallback(
    (thingID, onUpdate) => {
      if (!subscriptions.current) {
        return () => undefined;
      }

      if (!subscriptions.current[thingID]) {
        subscriptions.current[thingID] = {};

        sendMessage({
          type: 'SUBSCRIBE_MESSAGES',
        });
      }

      const subId = +new Date();

      subscriptions.current[thingID][subId] = onUpdate;

      // return unsubscribe function
      return () => {
        delete subscriptions.current[thingID][subId];

        // clean up after one second
        setTimeout(() => {
          // skip removal if it is removed already
          if (!subscriptions.current[thingID]) {
            return;
          }

          // when no subs are left: unsubscribe from observation
          if (Object.keys(subscriptions.current[thingID]).length === 0) {
            sendMessage({
              type: 'UNSUBSCRIBE_MESSAGES',
              payload: {
                thingID,
              },
            });

            delete subscriptions.current[thingID];
          }
        }, 1000);
      };
    },
    [sendMessage]
  );

  const value = useMemo(
    () => ({
      state,
      subscribe,
      sendMessage,
    }),
    [sendMessage, state, subscribe]
  );

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};

export default SocketContextProvider;
