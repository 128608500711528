import React, { useContext, useRef, useState, useEffect } from 'react';
import {
  AppShell,
  Alert as Message,
  createStyles,
  Header,
  Footer,
  Title,
  MediaQuery,
  Burger,
  useMantineTheme,
} from '@mantine/core';

import { DateTime } from 'luxon';

import { AlertCircle } from 'tabler-icons-react';
import ReactTimeAgo from 'react-time-ago';
import { JSONTree } from 'react-json-tree';

import { SocketContext } from '../context/SocketContext';
import Navigation from './Navigation';
import { Payload, DataContent } from '../types';

const channel = 'messages';

interface ThingData {
  messages: Payload[];
  topics: Set<string>;
  signals: Set<string>;
  lastUpdate: Date;
  updated: boolean;
}

const useStyles = createStyles((theme) => ({
  logo: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'left',
    height: '100%',
    marginRight: 20,
  },

  message: {
    marginBottom: 20,
    borderRadius: theme.radius.sm,
    '&:last-child': {
      marginBottom: 0,
    },
    '& div > ul': {
      backgroundColor: '#fff !important',
      borderRadius: theme.radius.sm,
      padding: '10px 20px 12px !important',
    },
  },
}));

function Dashboard() {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [activeThing, setActiveThing] = useState('View All');

  const { state, subscribe } = useContext(SocketContext);
  const socketData = useRef<Payload[]>([]);
  const thingData = useRef<Record<string, ThingData>>({});
  const [, setLastSocketMessage] = useState(+new Date());
  const [search, setSearch] = useState('');

  const limit = 100;

  const setThingSeen = (id: string) => {
    thingData.current[id] = { ...thingData.current[id], updated: false };
  };

  const mapIncomingData = (data: Payload) => {
    const { thingID, topic } = data;

    const message = {
      receivedAt: DateTime.utc().toISO(),
      ...data,
    };

    socketData.current = [message, ...socketData.current.slice(0, limit - 1)];

    const currentThingData = thingData.current[thingID] || {
      messages: [],
      topics: new Set(),
      lastUpdate: new Date(),
      updated: true,
    };

    currentThingData.messages = [
      message,
      ...currentThingData.messages.slice(0, limit - 1),
    ];

    currentThingData.topics.add(topic);
    currentThingData.lastUpdate = new Date();

    thingData.current[thingID] = currentThingData;

    setLastSocketMessage(+new Date());
  };

  useEffect(() => {
    if (state !== 'connected') {
      return () => {};
    }

    const unsubscribe = subscribe(channel, (incomingData) => {
      mapIncomingData(incomingData);
    });

    return () => {
      socketData.current = [];
      setLastSocketMessage(+new Date());

      unsubscribe();
    };
  }, [state, subscribe]);

  const filter = ({ thingID, values, topic }: Payload) => {
    if (!search) {
      return true;
    }

    if (thingID.indexOf(search) > -1) {
      return true;
    }

    if (topic.indexOf(search) > -1) {
      return true;
    }

    const { date, payload } = values as DataContent;

    if (date.indexOf(search) > -1) {
      return true;
    }

    if (payload.indexOf(search) > -1) {
      return true;
    }

    return false;
  };

  const doFilterThingData = () => {
    const socketDataFiltered = (socketData.current || []).filter(filter);
    const thingDataFiltered = Object.entries(thingData.current || {}).reduce(
      (acc, item) => {
        const [id, { messages, ...rest }] = item;

        const results = messages.filter(filter);

        if (results.length === 0) {
          return acc;
        }

        return { ...acc, [id]: { messages: results, ...rest } as ThingData };
      },
      {} as Record<string, ThingData>
    );

    return [socketDataFiltered, thingDataFiltered];
  };

  const [filterSocketData, filterThingData] = search
    ? doFilterThingData()
    : [socketData.current || [], thingData.current || []];

  const items = [
    { id: 'View All', updated: false },
    ...Object.entries(filterThingData).map(([id, { updated }]) => ({
      id,
      updated,
    })),
  ];

  const allMessages = filterSocketData;

  const thingMessages =
    (filterThingData &&
      (filterThingData as Record<string, ThingData>)[activeThing] &&
      (filterThingData as Record<string, ThingData>)[activeThing].messages) ||
    [];

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      navbar={
        <Navigation
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 600 }}
          items={items}
          activeThing={activeThing}
          setActiveThing={setActiveThing}
          setThingSeen={setThingSeen}
          setSearch={setSearch}
        />
      }
      footer={
        <Footer height={60} p="md">
          &copy; {new Date().getFullYear()} Intoto AS
        </Footer>
      }
      header={
        <Header height={70} p="md">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <div className={classes.logo}>
              <img src="/intoto.svg" alt="Intoto" />
            </div>
            <Title>Apparatus Live</Title>
          </div>
        </Header>
      }
    >
      {activeThing === 'View All'
        ? (allMessages as Payload[]).map((message: Payload) => {
            const { receivedAt } = message;

            const title = receivedAt && (
              <ReactTimeAgo
                timeStyle="round"
                date={DateTime.fromISO(receivedAt).toJSDate()}
                locale="en-US"
              />
            );

            return (
              <Message
                icon={<AlertCircle size={24} />}
                title={title}
                radius="xl"
                variant="filled"
                className={classes.message}
              >
                <JSONTree data={message} />
              </Message>
            );
          })
        : thingMessages.map((message: Payload) => {
            const title = (
              <ReactTimeAgo
                timeStyle="round"
                date={new Date()}
                locale="en-US"
              />
            );

            return (
              <Message
                icon={<AlertCircle size={24} />}
                title={title}
                radius="xl"
                variant="filled"
                className={classes.message}
              >
                <JSONTree data={message} />
              </Message>
            );
          })}
    </AppShell>
  );
}

export default Dashboard;
